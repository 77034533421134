<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Edit -->
    <b-modal
      id="modal-edit"
      v-model="isModalEdit"
      size="lg"
      centered
      title="Ubah Data KS"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label class="block" for="kodePks"
            >Kode KS: <span class="text-danger">*</span></label
          >
          <input-primary
            id="kodePks"
            v-model="modal.kodePks"
            required
            isDetail
            type="number"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="idAngsuran"
            >Angsuran ke: <span class="text-danger">*</span></label
          >
          <input-primary
            id="idAngsuran"
            v-model="modal.idAngsuran"
            required
            isDetail
            type="number"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="jumlahPelunasan"
            >Jumlah Pelunasan: <span class="text-danger">*</span></label
          >
          <input-primary
            id="jumlahPelunasan"
            v-model="modal.jumlahPelunasan"
            required
            isDetail
            type="number"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="tanggalJatuhTempo"
            >Tanggal Jatuh Tempo: <span class="text-danger">*</span></label
          >
          <flat-pickr
            id="tanggalJatuhTempo"
            class="form-control bg-light cursor-not-allowed"
            :config="basicConfig"
            v-model="modal.tanggalJatuhTempo"
            required
            disabled
            placeholder="Tanggal Jatuh Tempo"
          ></flat-pickr>
        </b-form-group>
        <b-form-group>
          <label class="block" for="idNomorSurat"
            >Nomor Surat: <span class="text-danger">*</span></label
          >
          <input-primary
            id="idNomorSurat"
            v-model="modal.idNomorSurat"
            required
            maxLength="100"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="idPasal"
            >Pasal: <span class="text-danger">*</span></label
          >
          <input-primary
            id="idPasal"
            v-model="modal.idPasal"
            required
            type="number"
            maxLength="100"
          />
        </b-form-group>
        <b-form-group>
          <label for="idUnit">Unit:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idUnit"
            class="w-full text-sm"
            placeholder="Pilih Unit"
            v-model="modal.idUnit"
            label="dataUnitKs"
            required
            :options="
              (listDataUnitPks &&
                listDataUnitPks.map(data => {
                  return {
                    dataUnitKs: data.kodeUnit + ` | ` + data.namaUnit,
                    idUnit: parseInt(data.idUnit)
                  }
                })) ||
                []
            "
            :reduce="listDataUnitPks => listDataUnitPks.idUnit"
          />
        </b-form-group>
        <b-form-group>
          <label class="block" for="idKepada"
            >Kepada: <span class="text-danger">*</span></label
          >
          <input-primary
            id="idKepada"
            v-model="modal.idKepada"
            required
            maxLength="100"
          />
        </b-form-group>
        <b-form-group>
          <label for="idModalUpbjj">UPBJJ:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idModalUpbjj"
            class="w-full text-sm"
            placeholder="Pilih Unit"
            v-model="modal.idModalUpbjj"
            label="dataUnitKs"
            required
            :options="
              (listDataUpbjj &&
                listDataUpbjj.map(data => {
                  return {
                    dataUnitKs: data.kodeUpbjj + ` | ` + data.namaUpbjj,
                    idUpbjj: parseInt(data.id)
                  }
                })) ||
                []
            "
            :reduce="listDataUpbjj => listDataUpbjj.idUpbjj"
          />
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalEdit">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="editData"
              :disabled="!isValidModal"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Edit -->
    <!-- BEGIN:Form Search -->
    <iq-card
      bodyClass="relative-background"
      class="iq-card-block iq-card-stretch iq-card-height"
    >
      <template v-slot:headerTitle>
        <h4 class="card-title">Surat Tagihan Biaya Pendidikan</h4>
      </template>
      <template v-slot:body>
        <!-- BEGIN:Form -->
        <div class="align-items-center p-2">
          <b-form>
            <b-form-group
              class="row mt-2"
              label-cols-sm="2"
              label="UPBJJ:"
              label-for="idUpbjj"
            >
              <input-datalists
                id="idUpbjj"
                class="w-full text-sm"
                placeholder="Pilih UPBJJ"
                v-model="form.idUpbjj"
                label="dataUpbjj"
                required
                :options="
                  (listDataUpbjj &&
                    listDataUpbjj.map(data => {
                      return {
                        dataUpbjj: data.kodeUpbjj + ` | ` + data.namaUpbjj,
                        id: parseInt(data.id)
                      }
                    })) ||
                    []
                "
                :reduce="listDataUpbjj => listDataUpbjj.id"
                :clearable="false"
              >
                <template #spinner="{ loading }">
                  <div v-if="loading" class="vs__spinner"></div>
                </template>
              </input-datalists>
            </b-form-group>
            <!-- Button -->
            <b-row class="pt-2 justify-content-center">
              <b-button
                class="btn-block w-25"
                variant="primary"
                type="button"
                @click="searchData"
                :disabled="!isValidForm"
                ><i class="ri-search-line"></i>Cari</b-button
              >
            </b-row>
          </b-form>
        </div>
        <!-- ./END:Form -->
      </template>
    </iq-card>
    <!-- ./END:Form Search -->
    <!-- BEGIN:Data Tagihan PKS -->
    <iq-card
      headerClass="bg-primary rounded-top"
      bodyClass="relative-background"
      class="iq-card-block iq-card-stretch iq-card-height"
    >
      <template v-slot:headerTitle>
        <h4 class="card-title text-white">Daftar Data Tagihan PKS</h4>
      </template>
      <template v-slot:body>
        <div class="align-items-center p-2">
          <!-- BEGIN:Table -->
          <b-col md="12">
            <iq-card
              bodyClass="relative-background"
              class="iq-card-block iq-card-stretch iq-card-height"
            >
              <template v-slot:body>
                <div class="align-items-center">
                  <b-row>
                    <b-col>
                      <b-form-input
                        class="w-25"
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Cari Data"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                  <b-table
                    responsive
                    bordered
                    :fields="thead"
                    :items="listData"
                    class="mt-2"
                    :busy="isLoading"
                    :filter="filter"
                    :per-page="perPage"
                    :current-page="currentPage"
                    @filtered="onFiltered"
                    show-empty
                  >
                    <template v-slot:cell(aksi)="listData">
                      <b-row>
                        <b-col>
                          <b-button
                            class="px-2 btn-block mb-1"
                            variant=" bg-success"
                            size="sm"
                            @click="showModalEdit(listData.item)"
                            ><i class="ri-ball-pen-line m-0"></i>Edit</b-button
                          ></b-col
                        >
                        <b-col>
                          <b-button
                            class="px-2 btn-block"
                            variant=" bg-primary"
                            size="sm"
                            @click="downloadDokumen()"
                            ><i class="ri-printer-line m-0"></i>Cetak</b-button
                          ></b-col
                        >
                      </b-row>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template #empty>
                      <div
                        class="
                          bg-light
                          text-danger
                          font-weight-bold
                          mx-auto
                          text-center
                        "
                      >
                        Data tidak ditemukan
                      </div>
                    </template>
                  </b-table>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Per page:"
                        label-for="per-page-select"
                        label-size="sm"
                        class="d-flex"
                      >
                        <b-form-select
                          class="ml-2"
                          id="per-page-select"
                          v-model="perPage"
                          :options="pageOptions"
                          size="sm"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-pagination
                        class="float-right"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </div>
              </template>
            </iq-card>
          </b-col>
          <!-- ./END:Table -->
        </div>
      </template>
    </iq-card>
    <!-- ./END:Data Tagihan PKS -->
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { Vue } from 'vue-property-decorator'
import store from '@/store/index'
import { MENU_ROLE } from '@/config/featureStatus'

export default {
  name: 'kerjasama.suratTagihanBiayaPendidikan',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status = this.form.idUpbjj !== null
      return status
    },
    isValidModal() {
      const status =
        this.modal.kodePks &&
        this.modal.idAngsuran &&
        this.modal.jumlahPelunasan &&
        this.modal.tanggalJatuhTempo &&
        this.modal.idNomorSurat &&
        this.modal.idPasal &&
        this.modal.idUnit &&
        this.modal.idKepada &&
        this.modal.idModalUpbjj !== null
      return status
    }
  },
  async mounted() {
    this.innitData()
    /** Set the initial number of items */
    this.totalRows = this.listData.length
  },
  methods: {
    /**
     * Trigger pagination to update the number of buttons/pages due to filtering
     */
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    /**
     * Reset Value
     */
    resetModal() {
      this.modal.kodePks = null
      this.modal.idAngsuran = null
      this.modal.jumlahPelunasan = null
      this.modal.tanggalJatuhTempo = null
      this.modal.idNomorSurat = null
      this.modal.idPasal = null
      this.modal.idUnit = null
      this.modal.idKepada = null
      this.modal.idModalUpbjj = null
    },
    /**
     * GET => List data Unit PKS
     */
    async getListDataUnitPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=listUnit`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataUnitPks = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Search data
     */
    searchData() {
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Edit data
     */
    showModalEdit(data) {
      this.resetModal()
      this.modal.id = data.id
      this.modal.kodePks = data.kodePks
      this.modal.idAngsuran = data.tahapPelunasan
      this.modal.jumlahPelunasan = data.jumlahPelunasan
      this.modal.tanggalJatuhTempo = data.tanggalJatuhTempo
      this.isModalEdit = true
    },
    editData() {
      this.resetModal()
      this.isModalEdit = false
      this.$toast.info('Sedang dalam pengembangan')
    },
    closeModalEdit() {
      this.resetModal()
      this.isModalEdit = false
    },
    /**
     * Download dokumen
     */
    downloadDokumen() {
      this.$toast.info('Sedang dalam pengembangan')
    },
    /**
     * Innit data
     */
    async innitData() {
      /** Get List data */
      await Promise.all([this.getListDataUnitPks_()])
    }
  },
  data() {
    return {
      typeData: 'suratTagihanBiayaPendidikan',
      // Tabel Options
      isLoading: false,
      filter: null,
      perPage: 5,
      pageOptions: [5, 10, 15, { value: 100, text: 'Semua' }],
      currentPage: 1,
      totalRows: 1,
      // isModal
      isModalEdit: false,
      // Date Picker
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d'
      },
      // List Data
      listDataUnitPks: [],
      listDataUpbjj: [
        {
          id: 1,
          kodeUpbjj: '21',
          namaUpbjj: 'Jakarta'
        }
      ],
      listData: [
        {
          id: 0,
          kodePks: '210016',
          tahapPelunasan: 1,
          jumlahPelunasan: 135882,
          tanggalJatuhTempo: '2015-12-30'
        }
      ],
      // Form
      form: {
        idUpbjj: null
      },
      // Modal
      modal: {
        id: null,
        kodePks: null,
        idAngsuran: null,
        jumlahPelunasan: null,
        tanggalJatuhTempo: null,
        idNomorSurat: null,
        idPasal: null,
        idUnit: null,
        idKepada: null,
        idModalUpbjj: null
      },
      // Table
      thead: [
        {
          label: 'Kode PKS',
          key: 'kodePks',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tahap Pelunasan',
          key: 'tahapPelunasan',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Jumlah Pelunasan',
          key: 'jumlahPelunasan',
          class: 'text-center',
          sortable: true
        },
        {
          label: 'Tanggal Jatuh Tempo',
          key: 'tanggalJatuhTempo',
          class: 'text-center',
          sortable: true
        },
        { label: 'Aksi', key: 'aksi', class: 'text-center w-25' }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    const idUnitKs = store.state.authService.idUnitKs
    if (MENU_ROLE.KERJASAMA_SURAT_TAGIHAN.includes(String(idUnitKs))) {
      return next()
    } else {
      Vue.$toast.error('Anda tidak memiliki hak akses')
      return next({ name: 'sidebar.dashboard' })
    }
  }
}
</script>
